<template>
 <div>
  <svg height="183" viewBox="0 0 183 183" width="183" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
   <defs>
    <linearGradient gradientUnits="objectBoundingBox" id="linear-gradient" x1="0.5" x2="0.5" y2="1">
     <stop :stop-color="color1" offset="0"/>
     <stop :stop-color="color2" offset="1"/>
    </linearGradient>
   </defs>
   <g id="icon_ticket" transform="translate(-1031 -2564)">
    <rect :fill="background" data-name="Rectangle 193" height="183" id="Rectangle_193" transform="translate(1031 2564)" width="183"/>
    <path d="M50.963,21.181,67.414,37.632,37.632,67.414,21.181,50.963ZM39.977,72.153,72.153,39.977a3.271,3.271,0,0,0,0-4.688L53.3,16.44a3.4,3.4,0,0,0-4.688,0L16.442,48.618a3.271,3.271,0,0,0,0,4.688L35.291,72.155a3.4,3.4,0,0,0,4.688,0ZM86.263,38.987,39.039,86.263a6.722,6.722,0,0,1-9.422,0l-6.56-6.56a10.052,10.052,0,0,0,0-14.16,9.64,9.64,0,0,0-7.08-2.916A9.64,9.64,0,0,0,8.9,65.542L2.385,58.98a6.725,6.725,0,0,1,0-9.424L49.608,2.385a6.722,6.722,0,0,1,9.422,0l6.508,6.508a9.64,9.64,0,0,0-2.916,7.08,9.64,9.64,0,0,0,2.916,7.08,10.052,10.052,0,0,0,14.16,0l6.56,6.508a6.722,6.722,0,0,1,0,9.422Z" fill="url(#linear-gradient)" id="ticket" transform="translate(1078.178 2614.193)"/>
   </g>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>